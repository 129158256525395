import CustomTable from 'components/Table'
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTRESPONDENTS } from 'state/actions';

function AssignList() {

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)

  const columns = [
    {
      title: 'All Respondents',
      dataIndex: 'full_name',
      // render: (text) => <a>{text}</a>,
    },  
    {
      title: '',
      dataIndex: 'start_date',
      render: (text) => <a>Task</a>,
    },
    {
      title: '',
      dataIndex: 'due_date',
      render: (text) => <a>Task</a>,
    },  
    {
      title: '',
      dataIndex: 'due_date',
      render: (text) => <a>Task</a>,
    }
  ]  

  const {projectRespondents} = useSelector(state => state.task);
  const [userData, setUserData] = useState([]);

  useEffect(()=>{
    setUserData(projectRespondents)
  },[projectRespondents])

  useEffect(()=>{
    if (selProject?.uuid) {
      dispatch(GETPROJECTRESPONDENTS({project_uuid : selProject.uuid }))
    }
  },[selProject])


  return (
    <React.Fragment>
      {/* <Toolbar isStatus /> */}
      <CustomTable tableData={userData} columns={columns} />
    </React.Fragment>
  )
}

export default AssignList

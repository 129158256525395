import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'

function UniversalMediaCard(props) {
    const { url = "", alt = "Dekko", className = "", wrapperclassName = "", details, project } = props;
    const [isImage, setIsImage] = useState(true);
    const [mediaType, setMediaType] = useState("image");
    const [mediaUrl, setMediaUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const checkIfImage = () => {
        if (url.match(/\.(jpg|jpeg|png|gif)$/i)) {
            console.log("Image");
            setIsImage(true)
            setMediaType("image");
        } else if (url.match(/\.(mp3|wav|ogg)$/i)) {
            console.log("audio");
            setMediaType("audio");
            setIsImage(true)
        } else {
            setIsImage(false)
            console.log("Video");
            setMediaType("video");
        }
        setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_key + "_" + project.uuid + "/task_" + details?.task_uuid + "/" + url);
        // setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_name + "_" + project.uuid + "/905cb802-7af1-4e49-b50f-78fd9b9eaf4c.MOV");
        setIsLoading(false);
    }

    useEffect(() => {
        if (!!url) {
            checkIfImage();
        }
    }, [url])
    

    const renderElement = (tagType) => {
        switch (tagType) {
            case "image":
                return (<img
                    src={mediaUrl}
                    onError={(e) =>
                        (e.target.onerror = null)(
                            (e.target.src =
                                "https://picsum.photos/100/100?grayscale")
                        )
                    }
                    className={className}
                />)

            case "video":
                return (<video controls className="!h-full" > <source src={mediaUrl} type="video/mp4"></source></video>)

            case "audio":
                return (
                    <div class="audio-player">
                        <div class="icon-container">
                            <svg xmlns="http://www.w3.org/2000/svg" class="audio-icon" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
                            </svg>
                            <audio src="https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1"></audio>
                        </div>
                    </div>
                )
            // return  (<audio controls className="!h-full" > <source src={mediaUrl} type="audio/mpeg"></source></audio >)

            default:
                break;
        }

    }

    return (
        <div className={`flex justify-center items-center ${wrapperclassName}`}>
            {
                (!isLoading && mediaType != "") ?
                    renderElement(mediaType)
                    :
                    <Skeleton.Image active={true} />
            }
            {/* {
                !isLoading ?
                    (mediaType == "image" ?
                        <img
                            src={mediaUrl}
                            onError={(e) =>
                                (e.target.onerror = null)(
                                    (e.target.src =
                                        "https://picsum.photos/100/100?grayscale")
                                )
                            }
                            className={className}
                        /> :
                        <video controls className="!h-full" >
                            <source src={mediaUrl} type="video/mp4"></source>
                        </video>)
                    :
                    <Skeleton.Image active={true} />
            } */}
        </div>
    )
}

export default UniversalMediaCard

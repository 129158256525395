const initialState = {
  tasks : [],
  overviewResponse : [],
  projectRespondents : [],
  isTaskDeleted: false,
  taskUpdated: false,
  taskscount:{}
 };

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_PROJECT_TASKS": {
      return {
        ...state,
        tasks: payload,
      };
    }
    case "SET_PROJECT_OVERVIEW": {
      return {
        ...state,
        overviewResponse: payload || [],
      };
    }
    case "SET_PROJECT_RESPONDENTS": {
      return {
        ...state,
        projectRespondents: payload,
      };
    }
    case "DELETE_PROJECT_TASKS": {
      return {
        ...state,
        isTaskDeleted: payload,
      };
    }
    case "UPDATE_TASK_SUCCESS": {
      return {
        ...state,
        taskUpdated: payload,
      };
    }
    case "SET_OVERVIEW": {
      return {
        ...state,
        taskscount: payload?.taskscount,
      };
    }
    default:
      return state;
  }
};

import { CalendarOutlined, ClockCircleOutlined, MoreOutlined, StarFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import Image from 'components/image'
import UniversalMediaCard from 'components/image/UniversalMediaCard';
import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';

const STATUS = {
    in_review: "In Review",
    approved: "Approved",
    rejected: "Rejected",
    redo: "Redo",
    expired: "Expired",
}
function MediaCard({ details, submitTask = {} }) {
    // const today = new Date();
    const { selProject } = useSelector(state => state.user)
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => submitTask(details, 'approved', 'Approve')}>Approve</div>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => submitTask(details, 'rejected', 'Reject')}>Reject</div>
            <div className='p-3 -m-2 cursor-pointer' onClick={() => submitTask(details, 'redo', 'Redo')}>Redo</div>
            {/* <div className='p-3 -m-2 cursor-pointer' onClick={() => submitTask(details.task_uuid, 'expired')}>Expired</div> */}
        </div>
    );

    return (
        <div className='bg-secondary rounded-xl cursor-pointer tile-card'>
            {/* <div className='bg-secondary rounded-xl min-h-[440px] w-[275px] cursor-pointer hover:transition-shadow shadow-md hover:shadow-theme hover:shadow-sm shadow-black '> */}
            {/* <div className='flex flex-col py-6 space-y-4'> */}
            <div className='flex flex-col pt-6 space-y-4'>
                <div className='flex flex-col justify-between items-center space-y-3 px-4 '>
                    <div className='flex items-start justify-between space-x-2 w-full'>
                        <div className='text-base text-card font-bold text-left capitalize'>{details?.task_title}</div>
                        <div className="cursor-pointer">
                            {
                                (details.submitted_task_status == "in_review") && (
                                    <Popover placement="bottomRight" title={""} content={content} trigger="click">
                                        <MoreOutlined />
                                    </Popover>
                                )
                            }
                        </div>
                    </div>
                    <div className='flex flex-col space-y-2 items-start justify-start w-full'>
                        {/* <div className='text-secondary text-xs'> <ClockCircleOutlined /> 02:30 min</div> */}
                        <div className='text-secondary text-xs'>{moment(details.submitted_date).format("DD MMM YYYY")}{" "} | {" "}{moment(details.submitted_date).format("LT")}</div>
                        <div className='flex items-center justify-between w-full'>
                            <div className='space-x-2 flex items-center'>
                                <Image url="profile.png" alt="profile" className="w-[25px]" />
                                <div className='text-base text-card capitalize'>{details.respondent_name}</div>
                            </div>
                            <div className={'text-xs status-tab ' + details.submitted_task_status}>
                                {STATUS[details.submitted_task_status]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-4 border-t border-white border-opacity-20'>
                    <UniversalMediaCard url={details.media_link} className="rounded-lg h-full w-full bg-cover" project={selProject} details={details} />
                    {/* <UniversalMediaCard url={details.media_link} className="rounded-lg h-[250px] w-full bg-cover" project={selProject} details={details} /> */}
                    <div className='content flex flex-col space-y-2 px-1'>
                        {/* <div className='text-secondary text-xs text-left'> <CalendarOutlined color='var(--text-secondary)' /> &nbsp; {today.toLocaleDateString("en-US", options)}</div> */}
                        <div className='flex items-center text-sm text-left py-2 leading-5'>
                            {details.comment}
                        </div>
                        <div className='flex items-center text-sm text-left space-x-1'>
                            <div>Rating:</div>
                            <StarFilled style={{ color: "#FFD700" }} />
                            <div className='text-secondary'> {details.rating || 4.5}</div>
                        </div>

                        <div className='flex flex-col items-start text-sm text-left pb-2'>
                            {/* <audio controls>
                                <source src="horse.ogg" type="audio/ogg"></source>
                            </audio> */}
                            {
                                !!details.audio_comment && (
                                    <React.Fragment>
                                        <div className='pb-1'>Audio comment:</div>
                                        <div class="audio-player">
                                            <div class="icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="audio-icon" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
                                                </svg>
                                                <audio src="https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1"></audio>
                                            </div>
                                        </div>

                                        {/* <UniversalMediaCard url={details.audio_comment} className="rounded-lg h-full w-full bg-cover p-1" project={selProject} details={details} /> */}
                                    </React.Fragment>
                                )
                            }
                        </div>

                        <div className='flex items-center text-sm text-left leading-3 pt-3 flex-wrap'>
                            {
                                details?.tags && details?.tags.split(",").map(tag =>
                                    <div className='px-3 py-2 mb-2 mr-2 rounded-3xl bg-tertiary capitalize'>{tag}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaCard

import { DatePicker } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';
import React from 'react'

function CustomDatePicker(props) {

    const locale = {
        ...en,
        lang: {
          ...en.lang,
          fieldDateFormat: 'YYYY-MM-DD',
          fieldDateTimeFormat: 'YYYY-MM-DD HH:mm',
          // fieldDateTimeFormat: 'YYYY-MM-DD HH:mm A',
          yearFormat: 'YYYY',
          cellYearFormat: 'YYYY',
        },
      };

      const disabledDate = (current) => {
        // Can not select days before today and today
        return current ;
        // return current && current < moment.endOf('day');
      };

    const onChange = (date, dateString) => {
        // console.log(date, dateString);
        props.onChangeHandle(date, dateString)
    };

    return (
        <div>
            <DatePicker
                size='large'
                className={'w-36 rounded-lg  text-primary py-2 px-3 ' + props.className}
                onChange={onChange}
                // disabledDate={disabledDate}
                locale={locale}
                {...props}
            />
        </div>
    )
}

export default CustomDatePicker

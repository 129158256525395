import MediaCard from 'components/Cards/mediaCard'
import ModalPopUp from 'components/Modal/index.';
import Toolbar from 'components/Toolbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTOVERVIEW, GETPROJECTRESPONDENTS, UPDATETASK, UPDATETASKSUCCESS } from 'state/actions';

function Overview() {

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { overviewResponse, taskUpdated } = useSelector(state => state.task)
  const [overviewSet, setOverviewSet] = useState([]);
  const [openPopup, setOpenPopup] = useState([false, {}, 'approved', 'Approve']);

  useEffect(() => {
    if (selProject?.uuid) {
      const payload = {
        project_uuid: selProject.uuid,
        page: 1,
        pageSize: 10
      }
      dispatch(GETPROJECTOVERVIEW(payload))
      dispatch(GETPROJECTRESPONDENTS({ project_uuid: selProject.uuid }))
    }
  }, [selProject])

  useEffect(() => {
    if (taskUpdated) {
      const payload = {
        project_uuid: selProject.uuid,
        page: 1,
        pageSize: 10
      }
      dispatch(GETPROJECTOVERVIEW(payload))
      dispatch(UPDATETASKSUCCESS(false))
    }
  }, [taskUpdated])

  const submitTask = (taskData, taskStatus, statusLabel) => {
    if (!!taskData.submitted_task_uuid) {
      setOpenPopup([true, taskData, taskStatus, statusLabel])
    }
  }

  const submitTaskFunction = (taskData, taskStatus) => {
    if (!!taskData.submitted_task_uuid) {
      const payload = {
        "submitted_task_uuid": taskData.submitted_task_uuid,
        "submitted_task_status": taskStatus
      }
      // console.log("payload-->", payload);
      dispatch(UPDATETASK(payload))
    }
  }

  useEffect(() => {
    setOverviewSet(overviewResponse);
  }, [overviewResponse])

  return (
    <React.Fragment>
      <Toolbar isDate isKeyword isRating isRespondent isStatus />
      <div className='py-3 tiles-masonry'>
        {
          overviewSet.length ?
            overviewSet.map((item, index) => (
              <MediaCard key={item + "_" + index} details={item} submitTask={submitTask} />
            ))
            :
            <div className='flex justify-center w-full mt-5'>No response found..!!</div>
        }
      </div>
      <ModalPopUp openPopup={openPopup}  submitTaskFunction={submitTaskFunction} />
    </React.Fragment>
  )
}

export default Overview
